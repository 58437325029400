import {Link} from 'react-router-dom';
import React, { useState,useEffect }  from 'react';

export default function Navbar(props) {
    const [navbarOpen, setNavbarOpen] = useState([]);
    const [bagsOpen, setBagsOpen] = useState([]);
    const [watchesOpen, setWathesOpen] = useState([]);
    const [show, setShow] = React.useState();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [bagBrands, setBagBrands] = useState([]);

    const selectd=props.selected;

    useEffect(() => {
        fetch("https://public-api.wordpress.com/rest/v1.1/sites/adilj137.wordpress.com/categories")
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    var cats=data.categories;
                    var bags=[];
                    for (let index = 0; index < cats.length; index++) {
                        if(cats[index].parent===33324){
                            bags.push(cats[index]);
                        };
                    }
                    setBagBrands(bags);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
      }, [])

    return (
        <>
        {console.log(bagBrands)}
        <nav class="px-2 bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
            <div class="z-10 container flex flex-wrap items-center justify-between mx-auto">
                <a href="/" class="flex items-center">
                    <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">QBF</span>
                </a>
                <button data-collapse-toggle="navbar-dropdown" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-dropdown" aria-expanded="false" onClick={() => setNavbarOpen(!navbarOpen)}>
                <span class="sr-only">Open main menu</span>
                <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                </button>
                <div className={"w-full md:block md:w-auto" + (!navbarOpen ? "" : " hidden") } id="navbar-dropdown">
                <ul class="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                    <li>
                    <Link to="/" class="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-white dark:bg-blue-600 md:dark:bg-transparent" aria-current="page">Home</Link>
                    </li>
                    <li>
                        <button id="dropdownNavbarLink" data-dropdown-toggle="dropdownNavbar" class="flex items-center justify-between w-full py-2 pl-3 pr-4 font-medium text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-gray-400 dark:hover:text-white dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent" onClick={() => setBagsOpen(!bagsOpen)}>Bags <svg class="w-5 h-5 ml-1" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></button>
                        <div id="dropdownNavbar" className={"font-normal bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600" + (!bagsOpen ? " absolute" : " hidden")}>
                            <ul class="py-1 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton">
                            {bagBrands.map(brand => (
                            <div key={(brand.slug).toString()}>
                                <li>
                                    <Link to={`/bags/${brand.slug}`} class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{(brand.name)}</Link>
                                </li>
                            </div>
                            ))}
                            </ul>
                        </div>
                    </li>
                    <li>
                    <Link to="/about" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">About</Link>
                    </li>
                    <li>
                    <Link to="/contact" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contact</Link>
                    </li>
                </ul>
                </div>
            </div>
        </nav>



            {/* <nav className="bg-slate-100 dark:text-white dark:bg-slate-900 border-gray-200 px-2 sm:px-4 py-2.5">
            <div className="container flex flex-wrap justify-between items-center mx-auto">
                <Link to="/" className="flex items-center">
                    <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Queen Butterfly</span>
                </Link>
                <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false" onClick={() => setNavbarOpen(!navbarOpen)}>
                <span className="sr-only">Open main menu</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                </button>
                <div className={
                        "justify-between items-center w-full md:flex md:w-auto md:order-1" +
                        (!navbarOpen ? "" : " hidden")
                    } id="navbar-default">
                <ul className={"flex flex-col p-4 mt-4 bg-slate-100 border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-slate-100 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"}>
                        <li>
                            <Link to="/" className={((selectd==='home')? "block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:dark:text-white md:text-black md:p-0 dark:text-white" : "block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-black md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700")}>Home</Link>
                        </li>
                        <li>
                            <Link to="/about" className={((selectd==='about')? "block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:dark:text-white md:text-black md:p-0 dark:text-white" : "block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-black md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700")}>About</Link>
                        </li>
                        <li>
                            <Link to="/course" className={((selectd==='course')? "block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:dark:text-white md:text-black md:p-0 dark:text-white" : "block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-black   md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700")}>Courses</Link>
                        </li>
                        <li>
                            <Link to="/blog" className={((selectd==='blog')? "block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:dark:text-white md:text-black md:p-0 dark:text-white" : "block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-black md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700")}>Blogs</Link>
                        </li>
                        <li>
                            <Link to="/contact" className={((selectd==='contact')? "block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:dark:text-white md:text-black md:p-0 dark:text-white" : "block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-black   md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700")}>Contact</Link>
                        </li>
                        </ul>
                </div>
            </div>
            </nav> */}
        </>
    );
}